<template>
<!-- 商标辅助注册页 -->
<div class="index">
  <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch />

  <!-- 本页主题图 -->
    <img src="../imger/AR/theme.png" alt="问标网商标专家辅导注册" class="theme">
    <ServerTop title="商标专家辅导注册" wera="全程商标专家一对一服务" jiage="580"/>
    <div class="content">
      <!-- 服务详情 -->
      <div class="AR01">
        <div class="header">
          <div class="lan"></div>
          <p>服务详情</p>
        </div>

        <div class="box">
          <div class="title">
            <img src="../imger/AR/title.png" alt="问标网" class="titletubiaoleft">
            <p>服务介绍</p>
            <img src="../imger/AR/title.png" alt="问标网" class="titletubiaoright">
          </div>
          <p class="wear">任何商标的注册都是有驳回风险的，平台提供商标专家辅导注册属于一种保障性措施，目的是帮客户前置把控和分析商标注册风险，最大程度提高商标注册成功率。</p>
        </div>

        <div class="box">
          <div class="title">
            <img src="../imger/AR/title.png" alt="问标网" class="titletubiaoleft">
            <p>服务优势</p>
            <img src="../imger/AR/title.png" alt="问标网" class="titletubiaoright">
          </div>
          <div class="advantage">
            <div class="advantageBox  tu01">
              <p>最大程度提高</p>
              <p>商标注册成功</p>
            </div>
            <div class="advantageBox tu02">
              <p>专业顾问全程</p>
              <p>一对一服务</p>
            </div>
            <div class="advantageBox tu03">
              <p>国家商标局</p>
              <p>备案正规机构</p>
            </div>
            <div class="advantageBox tu04">
              <p>大数据和</p>
              <p>人工双重检索</p>
            </div>
          </div>
        </div>

        <div class="box">
          <div class="title">
            <img src="../imger/AR/title.png" alt="问标网" class="titletubiaoleft">
            <p>拥有商标多好处</p>
            <img src="../imger/AR/title.png" alt="问标网" class="titletubiaoright">
          </div>
          <div class="benefi">
            <div class="benefibox">
              <div class="left">1</div>
              <div class="right">
                <p class="rightTitle">入驻电商必备</p>
                <p>入驻天猫，京东多必要门槛之一</p>
                <img src="../imger/AR/benefi01.png" alt="入驻电商必备" class="benefiicon01">
              </div>
            </div>
            <div class="benefibox margin">
              <div class="left">2</div>
              <div class="right">
                <p class="rightTitle">无形品牌资产</p>
                <p>创立无形资产，实现品牌价值消费者选择产品多强力依据</p>
                <img src="../imger/AR/benefi02.png" alt="无形品牌资产" class="benefiicon01">
              </div>
            </div>
            <div class="benefibox">
              <div class="left">3</div>
              <div class="right">
                <p class="rightTitle">市场准入必备</p>
                <p>办理质检，卫检，条码等的必备条件申请微信，微博官方认证必备</p>
                <img src="../imger/AR/benefi03.png" alt="市场准入必备" class="benefiicon01">
              </div>
            </div>
          </div>
          
        </div>
        <img src="../imger/AR/banner.png" alt="商标注册" class="banner">
      </div>
      
      <ServerFoot :data="serverData" :show="true"/>
      <ServerCl :show="true" Cl=""/>
    </div>
  
  <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer /> 
</div> 
</template>

<script>
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'
import Sidebar from '../components/sidebar.vue'
import Eject from '../components/eject.vue'
import Drawer from '../components/drawer.vue'
import ColumnSearch from '../components/columnSearch.vue'
import TradHeader from '../components/trademarkClassification/tradHeader.vue'
import ServerTop from '../components/serverTop.vue'
import ServerFoot from '../components/serverFoot.vue'
import ServerCl from '../components/serverCl.vue'

export default {
  components:{
    Sidebar,Footer,Header,Drawer,Eject,ColumnSearch,
    TradHeader,ServerTop,ServerFoot,ServerCl
  },
  data(){
    return{
      
      serverData:[
        {
          title:'双重检索 ',
          content:'全国大数据智能检索和人工专业检索双重精准检索 '
        },
        {
          title:'专属顾问服务',
          content:'专属资深顾问一对一服务，制定有效保护方案'
        },
        {
          title:'准备注册材料',
          content:'确认申请人资质，准备对应商标注册申请材料'
        },
        {
          title:'递交商标局',
          content:'资料审核通过，全程高效无缝对接提交商标局'
        },
        {
          title:'状态跟踪',
          content:'及时同步官方数据，注册进度在线及时可查'
        },
        {
          title:'获取证书',
          content:'商标注册通过后，统一派发商标注册证。 '
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">

 .tu01 {
  background-image: url("../imger/AR/advantage01.png");
 }
 .tu02 {
  background-image: url("../imger/AR/advantage02.png");
 }
 .tu03 {
  background-image: url("../imger/AR/advantage03.png");
 }
 .tu04 {
  background-image: url("../imger/AR/advantage04.png");
 }
.index {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .theme {
    width: 100%;
    height: 28.75rem /* 460px -> 28.75rem */;
  }
  .content {
    width: 74.875rem /* 1198px -> 74.875rem */;
    .AR01 {
      width: 100%;
      margin-top: 2.6875rem /* 43px -> 2.6875rem */;
      border: 0.0625rem /* 1px -> 0.0625rem */ solid #EAEAEA;
      padding-bottom: 2.75rem /* 44px -> 2.75rem */;
      margin-bottom: 2.75rem /* 44px -> 2.75rem */;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      #beizhu{
        font-size: 0.875rem /* 14px -> 0.875rem */;
        color: #999;
        position: relative;
        bottom: -1.25rem /* -20px -> -1.25rem */;
      }
      .header{
        width: 100%;
        height: 3.125rem /* 50px -> 3.125rem */;
        padding: 0.625rem /* 10px -> 0.625rem */;
        box-sizing: border-box;
        font-size: 1.25rem /* 20px -> 1.25rem */;
        color: #333333;
        background-color: #F7F7F7;
        display: flex;
        align-items: center;
        .lan {
          width: 0.3125rem /* 5px -> 0.3125rem */;
          height: 1.6875rem /* 27px -> 1.6875rem */;
          margin-right: 0.875rem /* 14px -> 0.875rem */;
          background-color: #4D89F1;
        }
      }
      .box {
        width: 100%;
        margin-top: 3.75rem /* 60px -> 3.75rem */;
        .title{
          width: 100%;
          font-size: 1.5rem /* 24px -> 1.5rem */;
          color: #333333;
          display: flex;
          justify-content: center;
          align-items: center;
          .titletubiaoleft {
            width: 1.625rem /* 26px -> 1.625rem */;
            height: 100%;
            margin-right: 1.25rem /* 20px -> 1.25rem */;
          }
          .titletubiaoright {
            width: 1.625rem /* 26px -> 1.625rem */;
            height: 100%;
            margin-left: 1.25rem /* 20px -> 1.25rem */;
          }
        }
        .wear {
          padding-left: 0.8125rem /* 13px -> 0.8125rem */;
          padding-right: 0.8125rem /* 13px -> 0.8125rem */;
          margin-top: 2.125rem /* 34px -> 2.125rem */;
          font-size: 1.25rem /* 20px -> 1.25rem */;
          color: #333333;
          line-height: 2.25rem /* 36px -> 2.25rem */;
          text-indent:2rem;   //首行缩进
        }
        .advantage {
          width: 100%;
          height: 13.125rem /* 210px -> 13.125rem */;
          margin-top: 3.375rem /* 54px -> 3.375rem */;
          display: flex;
          justify-content: space-evenly;
          .advantageBox {
            width: 15.625rem /* 250px -> 15.625rem */;
            height: 13.125rem /* 210px -> 13.125rem */;
            padding-top: 8.125rem /* 130px -> 8.125rem */;
            font-size: 1.125rem /* 18px -> 1.125rem */;
            background-size: contain;
            background-repeat: no-repeat;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        .benefi {
          width: 100%;
          height: 21.9375rem /* 351px -> 21.9375rem */;
          margin-top: 4.5rem /* 72px -> 4.5rem */;
          display: flex;
          .margin {
            padding-top:5rem /* 80px -> 5rem */;
            box-sizing: border-box;
          }
          .benefibox {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            .left {
              width: 1.0625rem /* 17px -> 1.0625rem */;
              height: 100%;
              font-size: 3.75rem /* 60px -> 3.75rem */;
              color: #4D89F1;
              margin-right: 1.5rem /* 24px -> 1.5rem */;
            }
            
            .right {
              width: 13.125rem /* 210px -> 13.125rem */;
              height: 100%;
              margin-top: 0.625rem /* 10px -> 0.625rem */;
              font-size: 0.875rem /* 14px -> 0.875rem */;
              color: #666666;
              line-height: 2.1875rem /* 35px -> 2.1875rem */;
              .rightTitle {
                font-size: 1.25rem /* 20px -> 1.25rem */;
                color: #4D4D4D;
              }
              .benefiicon01 {
                height: 4rem /* 64px -> 4rem */;
                width: 5.1875rem /* 83px -> 5.1875rem */;
                margin-left: 5.4375rem /* 87px -> 5.4375rem */;
                margin-top: 1.25rem /* 20px -> 1.25rem */;
              }
            }
          }
        }
      }
      .banner {
        width: 70.3125rem /* 1125px -> 70.3125rem */;
        height: 10rem /* 160px -> 10rem */;
      }
      .step {
          width: 100%;
          height: 18.8125rem /* 301px -> 18.8125rem */;
          position: relative;
          #stepWord{
            font-size: 0.875rem /* 14px -> 0.875rem */;
            color: #EF783B;
            position: absolute;
            bottom: -1.25rem /* -20px -> -1.25rem */;
            left: 50%;
            transform: translateX(-50%);
          }
          .lan {
            position: absolute;
            top: 5.8125rem /* 93px -> 5.8125rem */;
            left: 50%;
            transform: translateX(-50%);
            width: 56rem /* 896px -> 56rem */;
            height: 0.125rem /* 2px -> 0.125rem */;
            background-color: #FF840C;
          }
          .number {
            position: absolute;
            top: 4.625rem /* 74px -> 4.625rem */;
            left: 50%;
            transform: translateX(-50%);
            width: 73.125rem /* 1170px -> 73.125rem */;
            height: 11.875rem /* 190px -> 11.875rem */;
            display: flex;
            justify-content: space-evenly;
            .numberbox {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              .numberboxnum{
                width: 2.3125rem /* 37px -> 2.3125rem */;
                height: 2.3125rem /* 37px -> 2.3125rem */;
                background-color: #FFF0E1;
                border: 0.125rem /* 2px -> 0.125rem */ solid #FF840C;
                border-radius: 50%;
                font-size: 1.375rem /* 22px -> 1.375rem */;
                color: #FF840C;
                text-align: center;
                line-height: 2.3125rem /* 37px -> 2.3125rem */;
              }
              .numberboxtitle {
                font-size: 1.125rem /* 18px -> 1.125rem */;
                color: #333333;
              }
              .numberboxwear{
                width: 190px;
                font-size: 1rem /* 16px -> 1rem */;
                color: #666666;
                line-height: 1.6rem /* 25.6px -> 1.6rem */;
              }
            }
          }
        }
      .materials {
        width:100%;
        display: flex;
        margin-top: 2.5rem /* 40px -> 2.5rem */;
        .materialsBox{
          width: 50%;
          .title {
            font-size: 1.25rem /* 20px -> 1.25rem */;
            color: #555555;
            margin-left: 2.0625rem /* 33px -> 2.0625rem */;
          }
          .field{
            display: flex;
            font-size: 1.125rem /* 18px -> 1.125rem */;
            color: #666666;
            position: relative;
            line-height: 2.5rem /* 40px -> 2.5rem */;
            margin-left: 3.5rem /* 56px -> 3.5rem */;
            margin-top: 1.25rem /* 20px -> 1.25rem */;
            .num {
              line-height: 1.875rem /* 30px -> 1.875rem */;
              font-size: 2.25rem /* 36px -> 2.25rem */;
              color:#4D89F1
            }
            .bei {
              position: absolute;
              bottom: 0.625rem /* 10px -> 0.625rem */;
              left: 0.5rem /* 8px -> 0.5rem */;
              width: 14.1875rem /* 227px -> 14.1875rem */;
              height: 0.5rem /* 8px -> 0.5rem */;
            }
          }
        }
      }
    }
  }
}
</style>